<template>
  <div>
    <div style="display: flex; justify-content: center; padding: 10px 0 0 0;align-items: center; flex-direction: column" v-if="!selectedMessage && !messageLoading">
      <h1>Сообщение не найдено</h1>
      <a href="/expert">Назад к выбору идентификатора сообщения</a>
    </div>

    <div v-else-if="!messageLoading">
      <splitpanes :horizontal="isWide" :push-other-panes="false" class="mo-rewrite" >
        <pane  size="50" min-size="10" style="height: 74vh; margin-right: 2px">
          <pre v-if="!selectedMessage && !messageLoading" style="height: 100%; flex-grow: 1;">
            Сообщение не найдено
          </pre>
          <pre v-else style="height: 100%; display: flex; font-size: 18px">{{selectedMessage}}</pre>
        </pane>
        <pane size="50" :key="2" min-size="30"  height="100%"  style="padding: 10px; overflow-y: auto; height: 74vh">
          <div style="display: flex; justify-content: center; padding: 10px 0 0 0;align-items: center; flex-direction: column" v-if="messageError">
            <h1>{{messageError}}</h1>
            <a href="/expert">Назад к выбору идентификатора сообщения</a>
          </div>
          <div v-show="step === 1">
            <!--<a v-show="selectedAction" href="#" @click="goToEditParams">Вперед</a>-->
            <!--<br>-->
            <a href="http://www.ceme.gsras.ru/new/ssd.htm" target="_blank" style="font-size: 16px">Открыть сайт единой геофизической службы в новом окне</a>
            <br>
            <a href="http://www.ceme.gsras.ru/cgi-bin/new/equakes.pl" target="_blank" style="font-size: 16px">Самые последние события в новом окне</a>
            <br/>
            <!--<span @click="showSSD = !showSSD">Показать во фрейме</span>-->
            <!--<iframe v-if="showSSD"-->
            <!--id="ssd"-->
            <!--title="ssd"-->
            <!--width="100%"-->
            <!--style="height: 60vh"-->
            <!--src="http://www.ceme.gsras.ru/new/ssd.htm">-->
            <!--</iframe>-->
            <md-field :class="{'md-invalid': !selectedAction}"  style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >
              <label for="role">{{selectedAction ? 'Выбрано' : 'Выберите действие'}}</label>
              <md-select v-model="selectedAction" name="expert-action" id="expert-action" @md-selected="changeAction">
                <md-option disabled="" :value="null">Выберите действие</md-option>
                <md-option  v-for="(action, index) in expertActions" :key="index" :value="action.name">{{action.name}}</md-option>
              </md-select>
            </md-field>


            <md-field :class="{'md-invalid': !receiver}"  style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >
              <label for="role">{{receiver ? 'Получатель выбран' : 'Выберите получателя'}}</label>
              <md-select v-model="receiver" name="expert-receiver" id="expert-receiver">
                <md-option disabled="" :value="null">Выберите получателя</md-option>
                <md-option :value="'ROSGIDROMET'">Росгидромет</md-option>
                <md-option :value="'CENTER'">Ситуационный центр Росгидромета</md-option>
              </md-select>
            </md-field>

            <md-field class="dialogField">
              <label>Географическое место</label>
              <md-input v-model.trim="district" placeholder="Укажите географическое место (регион из геофизической службы)"  type="text" ></md-input>
              <span class="md-error">Поле не может длинее 255 символов</span>
            </md-field>

            <div>
              <div>Дата и время объясвления тревоги (ВСВ)</div>
              <div>
                <input  v-model="warningDate"
                        type="date"
                        id="alert-time"
                        name="alert-time"
                        min="2024-01-01"
                        required
                />
                <input type="time" v-model="warningTime" style="margin-left: 20px" required>
                <span style="margin-left: 10px"> ВСВ</span>
                {{warningDate}} {{warningTime}} - {{alertDateTime}}
              </div>
            </div>

            <hr>
            <!--<md-field class="dialogField" :class="{'md-invalid': !earthquakeDateValid}" >-->
            <md-field class="dialogField" >
              <label>Дата и время землетрясения (ВСВ)</label>
              <md-input :value="selectedDate" placeholder="Дата и время землетрясения (ВСВ)" disabled></md-input>
              <!--<span class="md-error">Формат должен быть ГГГГ-ММ-ДД ЧЧ:ММ</span>-->
            </md-field>          <!--<md-field class="dialogField" :class="{'md-invalid': !earthquakeDateValid}" >-->
            <md-field class="dialogField" >
              <label>Дата и время объясвления тревоги (МСК)</label>
              <md-input :value="alertDateTimeTitle" placeholder="Дата и время землетрясения (ВСВ)" disabled></md-input>
              <!--<span class="md-error">Формат должен быть ГГГГ-ММ-ДД ЧЧ:ММ</span>-->
            </md-field>


            <div style="display: flex; gap: 20px; align-items: baseline">
              <md-field class="dialogField" :class="{'md-invalid': !latitudeValid}" >
                <label>Широта, град</label>
                <md-input v-model.trim="latitude" placeholder="Широта"  type="number" min="-90" max="90" step="0.01" disabled></md-input>
                <span class="md-error">Поле должно быть валидным, мин 0, макс 90</span>
              </md-field>
              <md-field  style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >
                <label for="expert-latitude-side">Выберите полушарие</label>
                <md-select v-model="latitudeSide" name="expert-latitude-side" id="expert-latitude-side" disabled>
                  <md-option disabled="" :value="null">Выберите полушарие</md-option>
                  <md-option :key="1" :value="1">Северное</md-option>
                  <md-option :key="-1" :value="-1">Южное</md-option>
                </md-select>
              </md-field>
            </div>

            <div style="display: flex; gap: 20px; align-items: baseline">
              <md-field class="dialogField" :class="{'md-invalid': !longitudeValid}">
                <label>Долгота, град</label>
                <md-input v-model.trim="longitude" placeholder="Долгота"  type="number" min="-180" max="180" step="0.01" disabled></md-input>
                <span class="md-error">Поле должно быть валидным, мин 0, макс 180</span>
              </md-field>
              <md-field  style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >
                <label for="expert-longitude-side">Выберите полушарие</label>
                <md-select v-model="longitudeSide" name="expert-longitude-side" id="expert-longitude-side" disabled>
                  <md-option disabled="" :value="null">Выберите полушарие</md-option>
                  <md-option :key="1" :value="1">Восточное</md-option>
                  <md-option :key="-1" :value="-1">Западное</md-option>
                </md-select>
              </md-field>
            </div>



            <md-field class="dialogField" :class="{'md-invalid': !magnitudeValid}">
              <label>Магнитуда землетрясения</label>
              <md-input v-model.trim="magnitude" placeholder="Магнитуда землетрясения"  type="number" min="0" max="10" step="0.1" disabled></md-input>
              <span class="md-error">Поле не может быть пустым, мин 1, макс 10</span>
            </md-field>

            <md-field class="dialogField" :class="{'md-invalid': !depthValid}">
              <label>Глубина гипоцентра, км</label>
              <md-input v-model.trim="depth" placeholder="Глубина гипоцентра, км"  type="number" min="-100000" max="100000" disabled></md-input>
              <span class="md-error">Поле не может быть пустым</span>
            </md-field>

            <!--<md-field class="dialogField" :class="{'md-invalid': !commentValid}">-->
            <!--<label>Комментарий</label>-->
            <!--<md-textarea v-model="comment" disabled></md-textarea>-->
            <!--<span class="md-error">Поле не может быть более 256 символов</span>-->
            <!--</md-field>-->


            <!--<md-field   style="width: 400px; max-width: 70vw; margin-bottom: 20px; padding-left: 20px" >-->
            <!--<label for="role">Действия с таблицей населенных пунктов</label>-->
            <!--<md-select v-model="fillLocalities" name="expert-receiver" id="expert-receiver">-->
            <!--<md-option disabled="" :value="null">Таблица населенных пунктов</md-option>-->
            <!--<md-option :value="'yes'">Заполнять</md-option>-->
            <!--<md-option :value="'no'">Не заполнять</md-option>-->
            <!--</md-select>-->
            <!--</md-field>-->

            <md-button class="md-raised md-primary" @click="prepare()">Подготовить документы</md-button>

          </div>
          <div v-show="step === 2">
            <a href="#" @click="step = 1">Назад</a>
            <div  style="display: flex; justify-content: center; flex-direction: column; align-items: center">
              <h1>Документы готовы</h1>
              <div>
                <div style="display: flex; align-items: baseline; flex-wrap: wrap; flex-direction: column">
                  <div>Документ 1</div>

                  <md-button class="md-raised md-primary" v-show="!showDocument1" @click="showDocument1 = true">Посмотреть на этой странице</md-button>
                  <md-button class="md-raised md-primary" v-show="showDocument1" @click="showDocument1 = false">Закрыть просмотр</md-button>
                  <md-button class="md-raised md-primary" @click="showDocument(1)">Посмотреть в новом окне</md-button>
                </div>
                <iframe v-if="showDocument1"
                        id="doc1"
                        title="ssd"
                        width="100%"
                        style="height: 40vh; min-width: 45vw"
                        :srcDoc="document1"
                >
                </iframe>
                <!--<md-dialog :md-active.sync="showDocument1"-->
                <!--:before-close="() => showDocument1 = false"-->
                <!--&gt;-->
                <!--<md-dialog-content style="padding-top: 5px; padding-bottom: 5px">-->
                <!--<md-dialog-actions>-->
                <!--<md-button class="md-primary" @click="showDocument1 = false">Закрыть</md-button>-->
                <!--</md-dialog-actions>-->
                <!--<div v-html="document1" style="all: reset"></div>-->
                <!--</md-dialog-content>-->
                <!--</md-dialog>-->
              </div>
              <hr>
              <div>
                <div style="display: flex; align-items: baseline; flex-wrap: wrap; flex-direction: column; width: 100%">
                  <div>Документ 2</div>

                  <md-button class="md-raised md-primary" v-show="!showDocument2" @click="showDocument2 = true">Посмотреть на этой странице</md-button>
                  <md-button class="md-raised md-primary" v-show="showDocument2" @click="showDocument2 = false">Закрыть просмотр</md-button>
                  <md-button class="md-raised md-primary" @click="showDocument(1)">Посмотреть в новом окне</md-button>
                </div>
                <iframe v-if="showDocument2"
                        id="doc2"
                        title="ssd"
                        width="100%"
                        style="height: 40vh; min-width: 45vw"
                        :srcDoc="document2"
                >
                </iframe>
                <!--<md-dialog :md-active.sync="showDocument1"-->
                <!--:before-close="() => showDocument1 = false"-->
                <!--&gt;-->
                <!--<md-dialog-content style="padding-top: 5px; padding-bottom: 5px">-->
                <!--<md-dialog-actions>-->
                <!--<md-button class="md-primary" @click="showDocument1 = false">Закрыть</md-button>-->
                <!--</md-dialog-actions>-->
                <!--<div v-html="document1" style="all: reset"></div>-->
                <!--</md-dialog-content>-->
                <!--</md-dialog>-->
              </div>
              <!--<hr>-->
              <!--<div>-->

              <!--<div>Просмотреть документ 2 (<a href="#" target="_blank" @click.prevent="showDocument(2)">открыть в новом окне</a>)</div>-->
              <!--<iframe v-if="showSSD"-->
              <!--id="doc2"-->
              <!--title="ssd"-->
              <!--width="100%"-->
              <!--style="height: 40vh"-->
              <!--:srcDoc="document2"-->
              <!--&gt;-->
              <!--</iframe>-->

              <!--<md-dialog :md-active.sync="showDocument2"-->
              <!--:before-close="() => showDocument2 = false"-->
              <!--&gt;-->
              <!--<md-dialog-content style="padding-top: 5px; padding-bottom: 5px">-->
              <!--<md-dialog-actions>-->
              <!--<md-button class="md-primary" @click="showDocument2 = false">Закрыть</md-button>-->
              <!--</md-dialog-actions>-->
              <!--<div v-html="document2" style="all: revert"></div>-->
              <!--</md-dialog-content>-->
              <!--</md-dialog>-->
              <!--</div>-->
              <br>
              <md-button class="md-raised md-primary" @click="sendDocs()" :disabled="docsSending">{{sendTitle}}</md-button>
              <br>
              <div style="font-size: 20px">{{lastSendStatus}}</div>
              <div v-show="lastSendStatus">
                <md-button class="md-raised md-primary" @click="$router.push('/expert')">вернуться на главную</md-button>
                <md-button class="md-raised md-primary" @click="goLogout">Выйти из программы</md-button>
              </div>
            </div>
          </div>
          <div v-show="step === 'exit'">
            <a href="#" @click="step = 1">Назад</a>
            <br>
            <br>
            <br>
            <md-button class="md-raised md-primary" @click="$router.push('/expert')">Выйти из программы</md-button>
          </div>
        </pane>
      </splitpanes>

    </div>
  </div>
</template>

<script>

  import { Splitpanes, Pane } from 'splitpanes'
  import 'splitpanes/dist/splitpanes.css'
  import {getTemplates, parseMessage, prepareFile, sendDocs} from "../api/expert-controller";
  import {getMessage} from "../api/message-controller";
  import {getDateTime2, getFormattedDate} from "../utils/timezone";
  import {
    reformatDateInput,
    getDateTime,
    getHoursAndMinutes,
    // getUtcDateFromInput,
    getDate,
    compileDate
  } from "../utils/time";
  import {mapActions} from 'vuex'
  import {logout} from "../api/auth-controller";
  import {getItem} from "../utils/persistanceStorage";

  // const dateReg = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d$/
  const dateReg = /^\d\d.\d\d.\d\d\d\dT\d\d:\d\d$/

  export default {
    name: 'Expert',
    components: {Splitpanes, Pane},
    data() {
      return {
        warningDate: reformatDateInput(getDate(new Date())),
        warningTime: getHoursAndMinutes(new Date()),
        alertDateTime: new Date(),
        alertDateTimeTitle: getFormattedDate('Europe/Moscow', new Date()),
        // alertDateTimeSelected: getUtcDateFromInput(getFormattedDate('UTC', new Date()).replaceAll(", ", "T")),
        messageLoading: false,
        actionsLoading: false,
        sendTitle: 'отправить',
        lastSendStatus: '',
        docsSending: false,
        earthquakeDate: getDateTime2(new Date(), true),
        earthquakeDateLocal: getDateTime2(new Date(), false),
        selectedDate: null,
        messageError: '',
        selectedMessageId: null,
        selectedMessage: '',
        step: 0, // steps 1 - expert look ssd, 2 - expert see/modify data, 3 - expert see email doc template
        showSSD: true,
        selectedAction: '',
        expertActions: [],
        receiver: null,
        universalDate: null,
        mskDate: null,
        latitude: 0,
        latitudeSide: 1, // 1 - северной широты, (-1) - южной широты
        longitude: 0,
        longitudeSide: 1, // 1 - восточной долготы, (-1) - западной долготы
        district: '', // географическое место
        depth: 0,
        magnitude: 0,
        comment: '', // максимальная длина 256 символов
        fillLocalities: 'no',
        template1: null,
        template2: null,
        document1: '',
        document2: '',
        showDocument1: false,
        showDocument2: false
      }
    },
    watch: {
      warningDate() {
        this.formDate()
      },
      warningTime() {
        this.formDate()
      },
      // alertDateTimeSelected: {
      //   handler: function (val) {
      //     console.log('watch time ', val, getUtcDateFromInput(val))
      //     this.alertDateTime = getFormattedDate('Europe/Moscow', new Date(val))//getUtcDateFromInput(val, 'intl')
      //     // this.earthquakeDate = getDateTime2(val, true)
      //     // this.earthquakeDateLocal = getDateTime2(val)
      //     // try {
      //     //   this.earthquakeDate = Date.UTC()
      //     // } catch (e) {
      //     //   //ignore
      //     // }
      //     // // if (val instanceof  Date || (val instanceof String && Date.parse(val))) {
      //     //   this.earthquakeDate = this.selectedDate
      //     // }
      //     /* ... */
      //   },
      //   immediate: true
      // }
    },
    computed: {
      isWide() {
        if (this.resizeDate) {
          console.log(this.resizeDate, window.outerWidth)

          if (window.outerWidth < 799) {
            return true
          } else {
            return false
          }
        }
        return window.outerWidth < 799
      },

      // formValid() {
      //   return this.earthquakeDateValid && this.latitudeValid && this.longitudeValid && this.magnitudeValid && this.depthValid && this.commentValid
      // },
      earthquakeDateValid() {
        return dateReg.test(this.selectedDate)
      },
      latitudeValid() {
        return this.latitude >=-90 && this.latitude <= 90
      },
      longitudeValid() {
        return this.longitude >=-180 && this.longitude <= 180
      },
      magnitudeValid() {
        return this.magnitude >= 1 && this.magnitude <= 10
      },
      depthValid() {
        return this.depth >= -100000 && this.depth <= 100000
      },
      commentValid() {
        return !this.comment || this.comment.length <= 256
      }
    },
    methods: {
      ...mapActions(['showInfoAlert', 'stateLogout']),
      async goLogout() {
        // logger('goLogout')
        try {
          await logout()
        } finally {
          this.stateLogout()
          this.$router.push('/signin')
        }
      },
      async init() {
        // console.log('message', this.$route.params ? this.$route.params.id : 'empty')
        if (this.$route.params && this.$route.params.id && (+this.$route.params.id) > 0) {
          this.messageLoading = true
          try {
            let messageResp = await getMessage(this.$route.params.id)
            if (messageResp.code) {
              this.selectedMessage = messageResp.payload.content
            }
            let actionsResp = await getTemplates()
            if (actionsResp.code) {
              this.expertActions = actionsResp.payload

            }
            this.goToEditParams()
          } finally {
            this.messageLoading = false
          }
        }
      },
      async changeAction() {
        this.expertActions.some(action => {
          if (this.selectedAction === action.name) {
            if (action.templates) {
              this.template1 = action.templates[0]
              this.template2 = action.templates[1]
            } else {
              this.template1 = null
              this.template2 = null
            }
            return true
          }
        })
        if (!this.template1 && !this.template2) {
          this.step = 'exit'
        }

      },
      async goToEditParams() {
        if (!this.$route.params || !this.$route.params.id) return;

        let parseResp = await parseMessage(this.$route.params.id)
        if (!parseResp.code) {
          this.messageError = parseResp.data.message
          return;
        }
        if (parseResp.code && parseResp.payload) {
          this.step = 1
          let signal = parseResp.payload.signal
          this.signalId = signal.id
          this.selectedDate = getDateTime(signal.earthquakeDate, true)
          this.earthquakeDate = signal.earthquakeDate
          // this.selectedDate = dateInputToDateString(signal.earthquakeDate)

          this.latitude = signal.latitude
          if (this.latitude < 0) {
            this.latitudeSide = -1
            this.latitude *= -1
          }

          this.longitude = signal.longitude
          if (this.longitude < 0) {
            this.longitudeSide = -1
            this.longitude *= -1
          }
          this.magnitude = signal.magnitude
          this.depth = signal.depth ? signal.depth : 0
        }
      },
      async showDocument(number) {
        var myWindow = window.open("", "response", "resizable=yes");
        myWindow.document.write(number === 1 ? this.document1 : this.document2);
      },
      async prepare() {
        if (!this.selectedAction) {
          // this.showInfoAlert('Действие не выбрано')
          this.showInfoAlert('Действие не выбрано')
          return;
        }
        if (!this.receiver) {
          this.showInfoAlert('Получатель не выбран')
          return;
        }
        let data = {}
        data.receiver = this.receiver
        // data.warningDate = this.earthquakeDate
        data.warningDate = new Date(this.alertDateTime)
        data.district = this.district
        data.signalId = this.signalId
        // data.user = getFIO()

        if (!this.template1 && !this.template2) {
          this.step = 'exit'
          return;
        }

        if (this.template1) {
          let prepareResp = await prepareFile(Object.assign({}, data, {template: this.template1}))
          this.document1 = prepareResp
        }

        if (this.template2) {
          let prepareResp2 = await prepareFile(Object.assign({}, data, {template: this.template2}))
          this.document2 = prepareResp2
        }
        this.step=2
      },
      async sendDocs() {
        this.docsSending = true
        this.lastSendStatus = 'Отправка документов...'
        let data = {}
        data.receiver = this.receiver
        data.warningDate = new Date(this.alertDateTime)
        data.district = this.district
        data.signalId = this.signalId
        data.templates = []

        let sendResp

        try {
          if (this.template1) {
            data.templates.push(this.template1)
          }

          if (this.template2) {
            data.templates.push(this.template2)
          }

          if (this.template1 || this.template2) {
            sendResp = await sendDocs(data)
            if (sendResp && sendResp.code === 'SUCCESS') {
              this.showInfoAlert('Документы отправлены')
              this.lastSendStatus = 'Документы отправлены ' + new Date().toISOString()
            } else {
              this.lastSendStatus = 'Произошла ошибка при последней отправке документов'
            }
          } else {
            this.showInfoAlert('Не заданы шаблоны')
          }
        } finally {
          this.docsSending = false
        }
      },
      formDate() {
        if (this.warningDate && this.warningTime) {
          this.alertDateTime = compileDate(this.warningDate, this.warningTime)
          // console.log('formed date', new Date(this.alertDateTime))

          this.alertDateTimeTitle = getFormattedDate('Europe/Moscow', new Date(this.alertDateTime))
        } else {
          console.log('expert formDate', this.warningDate, this.warningTime)
        }
      }

    },
    async mounted() {
      if (!getItem('auth').roles.includes('expert')) {
        this.$router.push('/wrongRole')
      } else {
        console.log('expert mounted')
        this.init()
      }
    }
  }
</script>

<style scoped>

</style>